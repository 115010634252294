import React, { useContext } from 'react';
import { Layout, Seo } from 'components';
import { appContext } from 'providers/appProvider';

import { DosagePrescribing } from 'components/pages/prescribing/DosagePrescribing';
import { StoragePrescribing } from 'components/pages/prescribing/StoragePrescribing';
// import page style
import './prescribing-sprix.scss';

const pageTitle = 'Prescribing SPRIX';
const pageDescription = '';

const PrescribingSprixPage = () => {
  const { appConfigs } = useContext(appContext);
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  return (
    <Layout indication={siteIndications.hcp} className="hcp-prescribing-sprix">
      <Seo indication={siteIndications.hcp} pageTitle={pageTitle} />
      <DosagePrescribing />
      <StoragePrescribing />
    </Layout>
  );
};

export default PrescribingSprixPage;
