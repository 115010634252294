import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Button } from 'rebass';

// import { CommonContainer } from 'components/common-container';
import ContentBlock from 'components/content-block';
import Bolt from 'components/bolts';
import { CommonContainer } from 'components/common-container';

import thermostat from 'images/hcp/pages/prescribing-sprix/perscribing-sprix-thermostat.png';
import sprixHr from 'images/hcp/pages/prescribing-sprix/prescribing-sprix-hr.png';
import copay from 'images/hcp/pages/prescribing-sprix/prescribing-sprix-copay-bg.png';
import copayMobile from 'images/hcp/pages/prescribing-sprix/prescribing-sprix-copay-mobile.png';

export const StoragePrescribing = () => {
  const trackingPrescribingForm = () => {
    typeof window !== 'undefined' &&
      window.ga(
        'send',
        'event',
        'navigation',
        'HCP Prescribing Sprix Prescribing Form button',
        'HCP Prescribing Sprix Prescribing Form',
      );
  };
  return (
    <>
      <div id={'storage'}>
        <ContentBlock>
          <h2 className="block-title storage-title">
            STORAGE<sup>1</sup>
          </h2>
          <div className="m-top-20">
            <Row>
              <Col xs={1} className="display-non-mobile"></Col>
              <Col xs={11} md={4} mr={1} className="thermostat-container">
                <img className="thermostat" src={thermostat} />
              </Col>
              <Col xs={11} md={5}>
                <ul className="storage-list">
                  <li>Protect from light and freezing</li>
                  <li>
                    Store unopened SPRIX between 2°C to 8°C (36°F to 46°F)
                  </li>
                  <li>
                    During use, keep containers of SPRIX Nasal Spray at
                    controlled room temperature, between 15°C to 30°C (59°F to
                    86°F), out of direct sunlight
                  </li>
                  <li>
                    Bottles of SPRIX should be discarded within 24 hours of
                    priming
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </ContentBlock>
      </div>
      <div>
        <ContentBlock>
          <img className="blueHr" src={sprixHr} />
        </ContentBlock>
      </div>
      <div id={'direct'}>
        <ContentBlock>
          <h2 className="no-padding-bottom">
            <strong>DIRECT SAVINGS,</strong> DIRECT TO YOUR PATIENTS
          </h2>
          <h3 className="sub-headline">
            SPRIX® (ketorolac tromethamine) DIRECT provides your patients with
            their medication EXCLUSIVELY through <strong>SPRIX DIRECT</strong>
          </h3>
          {/* bolt animations */}
          <Bolt color={'ltBlue'} classes="pain-guy-left-top" small />
          <Bolt color={'blue'} classes="pain-guy-left-copay" small />
          <Bolt color={'blue'} classes="pain-guy-right-copay" small />
          <Bolt color={'gray'} classes="pain-guy-right-knee" small />

          <img src={copay} className="copay hidden-mobile" />
          {/* <img src={copayMobile} className="copay hidden-desktop" /> */}

          <h4 className="copay-headline">
            SPRIX DIRECT{' '}
            <span>offers support for your patients and your practice</span>
          </h4>
          <div className="border-box">
            <Row>
              <Col xs={6} className="copay-list">
                <h2 className="no-padding-bottom">Support for Patients</h2>
                <ul>
                  <li>
                    Automatic $0 co-pay offer for eligible patients with
                    commercial insurance
                  </li>
                  <li>
                    Quick home delivery of SPRIX with instructions for use
                  </li>
                </ul>
              </Col>
              <Col xs={6} className="copay-list">
                <h2 className="no-padding-bottom">Support for Your Practice</h2>
                <ul>
                  <li>Payer claim adjudication</li>
                  <li>
                    Registered pharmacist available for <nobr>24-hour</nobr>{' '}
                    pharmacy support
                  </li>
                  <li>Prior authorization assistance</li>
                </ul>
              </Col>
            </Row>
          </div>
          <h2 className="cardinal-headline no-padding-bottom no-padding-top">
            <strong>SPRIX DIRECT</strong>
          </h2>
          <a
            className="cta"
            href="/pdfs/sprix-direct-prescription-form-chsp.pdf"
            target={'_blank'}
            onClick={trackingPrescribingForm}
          >
            <Button
              backgroundColor={'#f7db4b'}
              big
              color={'#19386e'}
              height="50px"
            >
              Download the SPRIX DIRECT Prescription Form
            </Button>
          </a>
        </ContentBlock>
      </div>
      <div id="prescribe">
        <Row>
          <Col xs={12}>
            <CommonContainer classes="gray">
              <ContentBlock className="larger-width">
                <h2>
                  Prescribe to Scripts Rx <br />
                  for no copay card required
                </h2>
                <div className="prescribing-container">
                  <p className="center prescribe-copy">
                    <strong>Scripts Rx (formerly Mall Pharmacy)</strong>
                  </p>
                  <p className="center prescribe-copy">
                    1815 S. Meyers Road, Suite 100
                  </p>
                  <p className="center prescribe-copy">
                    Oakbrook Terrace, IL 60181
                  </p>
                  <p className="center prescribe-copy">
                    <strong>NCPDP/NABP:</strong> 1468481
                  </p>
                  <p className="center prescribe-copy">
                    <strong>NPI:</strong> 110 498 2230
                  </p>
                  <p className="center prescribe-copy">
                    <strong>Questions, call:</strong> (844) 977-7749
                  </p>
                  <p className="center prescribe-copy">
                    <strong>Fax:</strong> (844) 794-7275
                  </p>
                </div>
              </ContentBlock>
            </CommonContainer>
          </Col>
        </Row>
      </div>
    </>
  );
};
