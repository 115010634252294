import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

import { CommonContainer } from 'components/common-container';
import ContentBlock from 'components/content-block';

import sprayBox from 'images/hcp/pages/prescribing-sprix/prescribing-sprix-spray-box.png';
import sprayNose from 'images/hcp/pages/prescribing-sprix/prescribing-sprix-spray-nose.png';

export const DosagePrescribing = () => {
  return (
    <div id="dosing">
      <CommonContainer classes="gray">
        <ContentBlock>
          <h1>
            <span className="strong">DOSAGE</span> PRESCRIBING
          </h1>
          <h3>
            Dosing and Administration<sup className="fs-70">1</sup>
          </h3>
          <div className="yellow-box-container">
            <div className="yellow-box">
              <p className="lead-copy">
                For adult patients &lt;65, ≥110 lbs, and normal kidney function:
              </p>
              <h2 className="no-padding-top no-padding-bottom">
                SPRIX Nasal Spray
              </h2>
              <p className="no-pt-pb">1 Box (5 single-day bottles)*</p>
              <p className="no-pt-pb">
                Sig: 1 spray in <strong>EACH</strong> nostril{' '}
              </p>
              <p className="no-pt-pb">every 6-8 hours as needed</p>
              <p className="no-pt-pb">Max 4 doses per day, for up to 5 days</p>
            </div>
            <div className="yellow-box margin-lt-16">
              <p className="lead-copy">
                For adult patients ≥65, &lt;110 lbs, or with renal impairment:
              </p>
              <h2 className="no-padding-top no-padding-bottom">
                SPRIX Nasal Spray
              </h2>
              <p className="no-pt-pb">1 Box (5 single-day bottles)*</p>
              <p className="no-pt-pb">
                Sig: 1 spray in <strong>ONLY ONE</strong> nostril
              </p>
              <p className="no-pt-pb">every 6-8 hours as needed</p>
              <p className="no-pt-pb">Max 4 doses per day, for up to 5 days</p>
            </div>
          </div>
          <div>
            <div className="blue-containers">
              <div className="blue-container">
                <img src={sprayBox} />
                <div>
                  <h2 className="no-padding-top no-padding-bottom">
                    <strong>SPRIX</strong> is provided
                  </h2>
                  <p className="no-pt">
                    in a 5-bottle carton. Each bottle contains 8 precisely
                    metered doses.
                  </p>
                </div>
              </div>
              <div className="empty-blue-container"></div>
              <div className="blue-container">
                <img src={sprayNose} />
                <div>
                  <h2 className="no-padding-top no-padding-bottom">
                    <strong>SPRIX</strong> can be used:
                  </h2>
                  <ul>
                    <li>Every 6-8 hours</li>
                    <li>Up to 4 times per day</li>
                    <li>For up to 5 days</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="blue-legal-container">
            <p className="headline">
              SPRIX is not an inhaled product; patients should hold their breath
              during administration.<sup>1</sup>
            </p>
            <p className="standard-bottom-spacing">
              *Discard used SPRIX bottle after 24 hours
            </p>
            <ul className="standard-top-spacing">
              <li>
                The total duration of use of SPRIX<sup>&reg;</sup> alone or
                sequentially with other formulations of ketorolac (IM/IV or
                oral) must not exceed 5 days because of the potential for
                increasing the frequency and severity of adverse reactions
                associated with the recommended doses
              </li>
              <li>
                Do not use SPRIX concomitantly with other formulations of
                ketorolac or other NSAIDs
              </li>
            </ul>
          </div>
        </ContentBlock>
      </CommonContainer>
    </div>
  );
};
